import imageInput from '@/mixins/image-input.js';
import BookmakersPostModel from '../bookmakers/models/bookmakers-post.model';

export default {
    data() {
        return {
            item: {}
        }
    },
    methods: {
        approve() {
            let data = new BookmakersPostModel(this.item);

            let formData = new FormData();
            for (let key in data) {
                formData.append(key, data[key]);
            }

            this.$store.dispatch("bookmakers/updateItem", {id: this.id, data: formData});
        }
    },
    created() {
        this.$store.dispatch("bookmakers/getItemById", this.id).then((res) => {
            this.item = res;
        });
    },
    computed: {
        id() {
            return this.$route.params.id;
        },
    },
    mixins: [imageInput]
}
